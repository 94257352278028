// about area start
.bg-h-light{
    background: #F6F6F6;
}
.rts-about-inner-area-content{
    @media #{$sm-layout} {
        margin-top: 30px;
    }
}
.about-image-left{
    position: relative;
    max-width: max-content;
    @media #{$md-layout} {
        position: relative;
        max-width: 100%;
    }
    @media #{$sm-layout} {
        position: relative;
        max-width: 100%;
    }
    .thumbnail{
        @media #{$md-layout} {
            max-width: 100%;
            margin-bottom: 30px;
        }
        @media #{$sm-layout} {
            max-width: 100%;
            margin-bottom: 30px;
        }
        img{
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
    &::after{
        position: absolute;
        content: '';
        background: var(--color-primary);
        width: 12px;
        height: 239px;
        top: 0;
        right: -35px;
        position: absolute;
        content: "";
        background: var(--color-primary);
        width: 10px;
        height: 239px;
        top: 0;
        right: -20px;
    }
    .small-image{
        position: absolute;
        right: -50%;
        top: 62%;
        transform: translateY(-50%);
        @media #{$smlg-device} {
            left: 50px;
        }
    }
    .exp-badge{
        display: flex;
        align-items: center;
        background: var(--color-primary);
        max-width: max-content;
        padding: 39px 47px;
        position: absolute;
        bottom: 0;
        @media #{$md-layout} {
            padding: 20px 10px;
            right: 0;
        }
        @media #{$sm-layout} {
            padding: 20px 10px;
            right: 0;
        }
        .title{
            color: #fff;
            font-weight: 700;
            font-size: 36px;
            line-height: 48px;
            margin-bottom: 0;
        }
        span{
            margin-left: 20px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #FFFFFF;
        }
    }
    .small-image{
        img{
            @media #{$sm-layout} {
                max-width: 200px;
            }
        }
    }
}
.bg-feedback-about{
    background-image: url(../images/about/06.png);
}


.rts-team-bg-2{
    background-image: url(../images/about/05.jpg);
}
.feedback-about-swiper-wrapper{
    margin-top: 45px;
}

.single-feedback-about{
    padding: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #efefef;
    @media #{$small-mobile} {
        padding: 20px;
    }
    .head{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EFEFEF;
        .title{
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 0;
            color: #1F1F25;
        }
        span{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #74787C;
        }
    }
    .body{
        p{
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
            color: #1F1F25;
        }
    }
}

.bg-abc-wrapper{
    background-image: url(../images/about/05.jpg);
}

.cpmpay-story-single-about{
    a.thumnail{
        display: block;
        overflow: hidden;
        img{
            transition: .6s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.15);
            }
        }
    }
    .body{
        padding: 40px;
        background: #FFFFFF;
        @media #{$large-mobile} {
            padding: 10px;
        }
    }
    .header-story{
        display: flex;
        align-items: center;
        gap: 30px;
        .single{
            display: flex;
            align-items: center;
            i{
                color: var(--color-primary);
                margin-right: 10px;
            }
        }
    }
    a .title{
        margin-top: 20px;
        transition: .3s;
        &:hover{
            color: var(--color-primary);
        }
    }
}


.mySwiper-about-feedback{
    overflow: hidden;
    position: relative;
    padding: 50px 0;
    margin-top: -72px;
    .swiper-pagination{
        right: auto;
        text-align: left;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--color-primary);
    }
}


.actions-area{
    svg{
        cursor: pointer;
    }
}