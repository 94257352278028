
// working process atyle hear

.rts-single-working-process{
    background-image: url(../images/working-process/01.png);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    height: 386px;
    padding: 50px 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$smlg-device} {
        padding: 25px;
    }
    &:hover{
        .icon{
            background: var(--color-primary);
            svg{
                path{
                    fill: #fff;
                }
            }
        }
    }
    &::after{
        position: absolute;
        content: "";
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        background: #00000052;
        filter: blur(120px);
        border-radius: 50%;
        z-index: -1;
        opacity: 0.35;
    }
    .icon{
        width: 100px;
        height: 100px;
        background: #FEF6ED;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 27px;
        margin-left: auto;
        margin-right: auto;
        transition: .3s;
        svg{
            path{
                fill: var(--color-primary);
                transition: .3s;
            }
        }
    }
    .content{
        .title{
            @media #{$smlg-device} {
                font-size: 22px;
            }
        }
        p.disc{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        .step_number{
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 55px;
            height: 55px;
            background: var(--color-primary);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                color: #fff;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}









