// search style
.search-input-area {
    transition: all 500ms ease;
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 57px 0;
    background: white;
    box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-input-area.show {
    visibility: visible;
    transform: inherit;
    opacity: 1;
}
.search-input-area .search-input-inner {
    display: flex;
    align-items: center;
    position: relative;
}
.search-input-area .search-input-inner .input-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: auto;
}
.search-input-area .search-input-inner .input-div input {
    background: #F7F7F7;
    border-radius: 5px;
    height: 55px;
}
.search-input-area .search-input-inner .input-div button {
    max-width: max-content;
    padding: 19px;
    background: var(--color-primary);
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: block;
    margin-left: -9px;
    border-radius: 0 5px 5px 0;
}
.search-input-area .search-close-icon {
    cursor: pointer;
    position: absolute;
    right: 38px;
    top: 22px;
}
.search-input-area .search-close-icon i {
    position: relative;
    z-index: 1;
    color: var(--color-primary);
    transition: 0.3s;
    font-size: 18px;
    &:hover{
        color: #F7F7F7;
        &::after{
            background: var(--color-primary);
        }
    }
}
.search-input-area .search-close-icon i::after {
    position: absolute;
    height: 45px;
    width: 45px;
    content: "";
    border-radius: 5px;
    background: #fff8f3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: 0.3s;
}

#anywhere-home {
    cursor: url(../images/banner/shape/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;
}

#anywhere-home.bgshow {
    background: #0e1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 999;
    top: 0;
}